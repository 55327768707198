import React, { useState } from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import BreadCrumb from "../components/breadcrumb"
import Img from "gatsby-image"
import WdModal from '../components/modal'
import ModalPost from '../components/modalpost'
import Success from '../components/success'
import { GTMPhoneClick } from "../lib/gtag"

const Service = ({pageContext,path,data}) => {
  var metainfo = {
    title: pageContext.metainfo? (pageContext.metainfo.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME  : (pageContext.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME,
    description:pageContext.metainfo? pageContext.metainfo.description || '' : '',
    image:'/images/logo.png'
  }

  const [reveal, setReveal] = useState(false)
  const [revealsuccess,setRevealSuccess] = useState(false)

  return (
    <Layout metainfo={metainfo}>

      <WdModal setRevealSuccess={setRevealSuccess} setReveal={setReveal} reveal={reveal}/>
      <ModalPost reveal={revealsuccess} setReveal={setRevealSuccess}>
        <Success/>
      </ModalPost>

      <BreadCrumb title={pageContext.title}/>

      <div className="service-details-section my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="single-service">

                <h2>Μεταφορές Αξιόπιστα!</h2>
                <p>
                  Σας Μεταφέρουμε με συνέπεια και ασφάλεια τις τελευταίες 3 δεκαετίες. Είτε πρόκειται για οικία ή επαγγελματικό χώρο, ξέρουμε ότι οι πελάτες μας έχουν διαφορετικές ανάγκες. Αυτός είναι ο λόγος που κάθε μετακόμιση είναι ξεχωριστή.
                </p>
                <p>
                  Όποια και αν είναι η περίπτωση σας, παρέχουμε ένα πλήρες μενού υπηρεσιών μεταφοράς. Αυτό σημαίνει πως εξατομικεύουμε ολόκληρη τη διαδικασία ώστε να ανταποκρίνεται στις δικές σας ανάγκες, το χρονοδιάγραμμα και τον προϋπολογισμό.
                </p>

              </div>

            </div>

            <div className="col-md-5">
              <div className="row">

                <div className="col-12 my-2">
                  <div className="single-service-bg">
                    <Img fluid={data.metakomisi.edges[0].node.childImageSharp.fluid}
                         alt={`metakomisi`}
                    />
                  </div>
                </div>

                {/*{data.metakomisi.edges.map((item) => {*/}
                {/*  return (*/}
                {/*    <div className="col-12 my-2">*/}
                {/*      <div className="single-service-bg">*/}
                {/*        <Img fluid={item.node.childImageSharp.fluid}*/}
                {/*             alt={`metakomisi`}*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}

                {/*  )*/}
                {/*})}*/}

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="quotation-block mt-4">
                <div className="quotation-text py-4 text-center">
                  <h4>Η κάθε μετακόμιση είναι ξεχωριστή</h4>
                  <p>Ζητήστε μια προσφορά για τις ποιοτικές υπηρεσίες μεταφοράς και αποθήκευσης που μπορούμε να σας παρέχουμε.</p>
                  <div className="request-button">
                    <button onClick={()=>setReveal(true)} type="button" className="btn btn-request">ΜΑΘΕ ΤΟ ΚΟΣΤΟΣ<i className="las la-arrow-right" /></button>
                  </div>
                </div>
                <div className="quotation-dtl text-white">
                  <p><i className="las la-mobile" />Μπορείτε να μας βρείτε στα τηλέφωνα: <a onClick={()=> GTMPhoneClick()} href="tel:6949873177">694 987 3177</a>, <a onClick={()=> GTMPhoneClick()} href="tel:6942073329">694 207 3329</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-details-section my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="single-service">
                <div>
                  <h2>Πακετάρισμα</h2>
                  <p>Εξειδικευμένο προσωπικό από την εταιρεία μας έρχεται στο χώρο σας και πακετάρει όλες τις οικοσκευές σας όπως ρούχα, πιάτα, ποτήρια, βιβλία, ευαίσθητες μικροσυσκευές και άλλα, πάντα με προσοχή μέσα σε ειδικά χαρτόκουτα ή συσκευασίες που εξασφαλίζουν την ασφαλή αποθήκευση και μεταφορά. Με την πολυετή εμπειρία μας, παρέχουμε υπηρεσίες υψηλής ποιότητας εγγυώμενοι την ασφαλή και ταχύτατη μεταφορά των οικοσκευών ή των εμπορευμάτων σας στο χώρο που επιθυμείτε.</p>
                  <h2>Αποθήκευση</h2>
                  <p>Στις αποθήκες μας, οι οποίες ακολουθούν όλες τις απαραίτητες τεχνικές προδιαγραφές ώστε να εξασφαλίζουν λειτουργικότητα και ευελιξία, μπορείτε με σιγουριά να αποθηκεύσετε τις οικοσκευές ή τα εμπορεύματα σας για το χρονικό διάστημα που επιθυμείτε.</p>
                  <h2>Μεταφορά</h2>
                  <p>Πραγματοποιούμε κάθε είδους μεταφορά ή μετακόμιση με το άρτια εκπαιδευμένο και εξοπλισμένο προσωπικό μας με συνέπεια και αξιοπιστία. Είμαστε προσεκτικοί κατά τη τοποθέτηση όλων των αντικειμένων σας στο φορτηγό ώστε να εξασφαλίσουμε την άψογη μεταφορά τους.</p>
                  <h2>Αναβατόριο</h2>
                  <p>Ένα δεν υπάρχει ασανσέρ ή κάποια από τα αντικείμενα / έπιπλα σας δεν μπορούν ναμεταφερθούν με άλλο τρόπο διαθέτουμε ανυψωτικό μηχάνημα.Παρέχουμε αδιαπραγμάτευτη ποιότητα στις υπηρεσίες μας στην καλύτερη δυνατή τιμή. Επικοινωνήστε τώρα μαζί μας ώστε να βρούμε την καλύτερη λύση για εσάς.</p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">

                <div className="col-12 my-2">
                  <div className="single-service-bg">
                    <Img fluid={data.metakomisi.edges[1].node.childImageSharp.fluid}
                         alt={`metakomisi`}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Service


export const query = graphql`
  query {

    metakomisi:allFile(filter: {name: {in: ["metakomisi1", "metakomisi2"]}}) {
   edges {
     node {
       childImageSharp {
         fluid(maxWidth: 500,quality:80) {
           ...GatsbyImageSharpFluid
         }
       }
     }
   }
 }




  }
`
